/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BASE_URL, STATIONS } from '../api/config';
import GlobalStyle from '../theme/GlobalStyle';

import LandingPage from './landingPage/landingPage';
import Station from './station/station';
import './Style.scss';

export interface StationsContextData {
  stationsList: any;
  loadingStationsList: boolean;
  errorStationsList: boolean;
}

export const StationsContextDefaultValue: StationsContextData = {
  stationsList: [],
  loadingStationsList: false,
  errorStationsList: false,
};

export const StationsContext = React.createContext<StationsContextData>(
  StationsContextDefaultValue,
);

const App = () => {
  const { i18n } = useTranslation();
  const [languagePl, setLanguagePl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguagePl(!languagePl);
  };

  const [backToLandingPage, setBackToLandingPage] = useState(false);
  const timeRunning = 90 * 1000;
  let backTimeout: any;
  const back = () => {
    setBackToLandingPage(true);
  };
  const setTimeouts = () => {
    backTimeout = setTimeout(back, timeRunning);
  };
  const clearTimeouts = () => {
    if (backTimeout) clearTimeout(backTimeout);
  };
  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchmove'];
    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
      setBackToLandingPage(false);
    };
    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    setTimeouts();
    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [setBackToLandingPage]);

  const [stationsList, setStationsList] = useState([]);
  const [loadingStationsList, setLoadingStationsList] = useState(false);
  const [errorStationsList, setErrorStationsList] = useState(false);
  useEffect(() => {
    let unmounted = false;
    setLoadingStationsList(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}`,
    })
      .then((response) => {
        if (!unmounted) {
          setStationsList(response.data);
          setErrorStationsList(false);
          setLoadingStationsList(false);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
          setErrorStationsList(true);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  const SingleStation = () => {
    const { id } = useParams<{ id: string }>();

    return <Station station={stationsList.find((station: { id: string }) => station.id === id)} />;
  };

  return (
    <div className="app">
      <GlobalStyle />
      <StationsContext.Provider value={{ stationsList, loadingStationsList, errorStationsList }}>
        {backToLandingPage && <Redirect to="/" />}
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/:id">
            <SingleStation />
          </Route>
        </Switch>
      </StationsContext.Provider>
    </div>
  );
};
export default App;
