import { useTranslation } from 'react-i18next';
import { getIcon } from '../../helpers/weather';
import styles from './weatherBox.module.scss';

type WeatherProps = {
  weatherData: {
    sunset: string;
    sunrise: string;
    weatherCode: number;
    temperature: number;
    perceivedTemperature: number;
    windDirection: string;
    windStrength: number;
    humidity: number;
    atmosphericPressure: number;
  };
};

const WeatherBox = ({ weatherData }: WeatherProps) => {
  const { t } = useTranslation();
  const date = new Date();

  return (
    <div className={styles.weather}>
      <h2 className={styles.weather__name}>{t('weatherBox.actual')}</h2>
      <div className={styles.weather__box}>
        <div className={styles.weather__actual}>
          <img
            className={styles.weather__icon}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
              date.getHours() < Number(weatherData?.sunset?.split(':')[0]) &&
              date.getHours() > Number(weatherData?.sunrise?.split(':')[0])
                ? 'day'
                : 'night'
            }/${getIcon(weatherData.weatherCode)}`}
            alt="Ikona pogody"
          />
          <p className={styles.weather__temperature}>{weatherData.temperature}&deg;C</p>
        </div>
        <ul className={styles.weather__column}>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/temperature.svg`}
              alt="Point icon"
            />
            <span style={{ fontWeight: 400 }}>
              {t('weatherBox.perceivedTemperature')}&nbsp;
              <span>{weatherData.perceivedTemperature}&deg;C</span>
            </span>
          </li>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/wind.svg`}
              alt="Point icon"
            />
            {t('weatherBox.wind')}&nbsp;
            <span>
              {weatherData.windDirection}&nbsp;
              {weatherData.windStrength}&nbsp;km/h
            </span>
          </li>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/sunrise.svg`}
              alt="Point icon"
            />
            {t('weatherBox.sunrise')}&nbsp;
            <span>{weatherData.sunrise}</span>
          </li>
        </ul>
        <ul className={styles.weather__column}>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/humidity.svg`}
              alt="Point icon"
            />
            {t('weatherBox.humidity')}&nbsp;<span>{weatherData.humidity}&nbsp;%</span>
          </li>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/pressure.svg`}
              alt="Point icon"
            />
            {t('weatherBox.pressure')}&nbsp;<span>{weatherData.atmosphericPressure} hPa</span>
          </li>
          <li className={styles.weather__paragraph}>
            <img
              className={styles.weather__iconSmall}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/sunset.svg`}
              alt="Point icon"
            />
            {t('weatherBox.sunset')}&nbsp;<span>{weatherData.sunset}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WeatherBox;
