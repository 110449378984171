import './newsPage.scss';

const NewsPage = () => (
  <div className="container">
    <div className="news">
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
      <img
        style={{ width: '44rem', height: '62rem' }}
        src={`${process.env.PUBLIC_URL}/assets/img/News_box.png`}
        alt="Feed"
      />
    </div>
  </div>
);

export default NewsPage;
