import { useTranslation } from 'react-i18next';
import styles from './weatherChart.module.scss';
import Chart from './chart';
import { getIcon } from '../../helpers/weather';

type WeatherProps = {
  weatherData: {
    sunset: string;
    sunrise: string;
  };
  weatherForecast: [];
};

type ForecastProps = {
  hour: string;
  weatherCode: number;
};

const WeatherChart = ({ weatherData, weatherForecast }: WeatherProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.chart}>
      <p className={styles.chart__name}>{t('weatherChart.forecast')}</p>
      <div className={styles.chart__box}>
        <div className={styles.chartBox}>
          {weatherForecast.map((forecast: ForecastProps) => (
            <img
              className={styles.chart__icon}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
                Number(forecast.hour.split(':')[0]) < Number(weatherData?.sunset?.split(':')[0]) &&
                Number(forecast.hour.split(':')[0]) > Number(weatherData?.sunrise?.split(':')[0])
                  ? 'day'
                  : 'night'
              }/${getIcon(forecast.weatherCode)}`}
              alt="Ikona pogody"
              key={forecast.hour}
            />
          ))}
          <Chart weatherForecast={weatherForecast} />
        </div>
      </div>
      <div className={styles.legendBox}>
        <div className={styles.legendBox__box}>
          <div className={styles.legendBox__line} />
          <p className={styles.legendBox__paragraph}>{t('weatherChart.temperature')}</p>
        </div>
        <div className={styles.legendBox__box}>
          <div className={styles.legendBox__lineDot} />
          <p className={styles.legendBox__paragraph}>{t('weatherChart.perceivedTemperature')}</p>
        </div>
      </div>
    </div>
  );
};

export default WeatherChart;
