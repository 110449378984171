import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as News } from '../../assets/icon/news.svg';
import { ReactComponent as Lifts } from '../../assets/icon/lifts.svg';
import { ReactComponent as Slopes } from '../../assets/icon/slopes.svg';
import { ReactComponent as Weather } from '../../assets/icon/weather.svg';
import { ReactComponent as Webcams } from '../../assets/icon/webcams.svg';
// import { ReactComponent as Maps } from '../../assets/icon/maps.svg';
import './navigation.scss';

type StationProps = {
  station:
    | {
        id: string;
      }
    | undefined;
};

const Navigation = ({ station }: StationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <nav className="navigation">
        <ul className="navigation__list">
          {/* <li>
            <NavLink
              to={`/${station?.id}/news`}
              exact
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <News className="navigation__button-icon" />
              </div>
              {t('navigation.news')}
            </NavLink>
          </li> */}
          <li>
            <div className="navigation__link--disable">
              <div className="navigation__button">
                <News className="navigation__button-icon--disable" />
              </div>
              {t('navigation.news')}
            </div>
          </li>
          <li>
            <NavLink
              to={`/${station?.id}/lifts`}
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <Lifts className="navigation__button-icon" />
              </div>
              {t('navigation.lifts')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${station?.id}/slopes`}
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <Slopes className="navigation__button-icon" />
              </div>
              {t('navigation.slopes')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${station?.id}/weather`}
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <Weather className="navigation__button-icon" />
              </div>
              {t('navigation.weather')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${station?.id}/webcams`}
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <Webcams className="navigation__button-icon" />
              </div>
              {t('navigation.webcams')}
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={`/${station?.id}/maps`}
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              <div className="navigation__button">
                <Maps className="navigation__button-icon" />
              </div>
              {t('navigation.maps')}
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </>
  );
};
export default Navigation;
