import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

html {
    font-size: 0.52vh;
    font-family: 'Poppins', sans-serif;
    ::-webkit-scrollbar {
      display: none;
    }
}

body {
     -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    color: #242424;
    background-color: #E3F4FE;
    background-color: linear-gradient(162.53deg, #E9F6FF 0%, #E3F4FE 100.28%);
}

button {
    outline: none;

    :focus {
        border: none;
    }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
`;

export default GlobalStyle;
