import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { ReactComponent as PlayStop } from '../../assets/play_video.svg';
import './webcam.scss';

type WebcamProps = {
  webcam: {
    url: string;
    name: string;
    cover: string;
  };
};

const Webcam = ({ webcam }: WebcamProps) => {
  const [play, setPlay] = useState(false);
  const [cover, setCover] = useState(true);
  const [errorUrl, setErrorUrl] = useState(false);
  const [load, setLoad] = useState(true);

  const togglePlay = () => {
    setCover(false);
    setPlay(true);
  };

  const toggleStop = () => {
    setPlay(false);
  };

  const handleErrorUrl = () => {
    setErrorUrl(!errorUrl);
  };

  const handelLoad = () => {
    setLoad(!load);
  };

  return (
    <>
      {!errorUrl && (
        <div className={load ? 'webcam--hide' : 'webcam'}>
          {cover && <img className="webcam__cover" src={webcam.cover} alt="Webcam cover" />}
          <ReactPlayer
            className="webcam__player"
            url={webcam.url}
            playing={play}
            muted
            onClick={toggleStop}
            onError={handleErrorUrl}
            onReady={handelLoad}
            width="100%"
            height="100%"
          />

          <button
            type="button"
            onClick={togglePlay}
            className={!play ? 'webcam__button' : 'webcam__button--hide'}
          >
            <PlayStop className="webcam__button-icon" />
          </button>
          <div className="webcam__name">
            <h2 className="webcam__name-title">{webcam.name}</h2>
            <div className="webcam__name-box">
              <div className="webcam__name-dot" />
              <p className="webcam__name-text">Online</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Webcam;
