import styled from 'styled-components';

const PH = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #e4e4e4;
  transition: ease 0.2s all;
`;

type PlaceholderProps = {
  bgColor?: string;
};

const Placeholder = ({ bgColor }: PlaceholderProps) => <PH style={{ backgroundColor: bgColor }} />;

Placeholder.defaultProps = {
  bgColor: false,
};

export default Placeholder;
