import { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StationsContext } from '../App';
import Placeholder from '../../helpers/placeholder';

import HomeImage from '../../assets/icon_home.svg';
import LogoTSS from '../../assets/logo_tss.svg';
import LoaderDots from '../../helpers/loader';
import StationCard from '../../components/stationCard/stationCard';

import './landingPage.scss';

type StationsProps = {
  stationsList: [
    {
      id: string;
      name: string;
      open: boolean;
      openLifts: number;
      totalLifts: number;
      openRuns: number;
      totalRuns: number;
    },
  ];
  loadingStationsList: boolean;
  errorStationsList: boolean;
};

const LandingPage = () => {
  const { t } = useTranslation();
  const { stationsList, loadingStationsList, errorStationsList }: StationsProps = useContext(
    StationsContext,
  );

  const [loaded, setLoaded] = useState(false);
  const refImage = useRef<HTMLImageElement>(null);
  const onLoad = () => {
    setLoaded(true);
  };
  useEffect(() => {
    if (refImage.current && refImage.current.complete) {
      onLoad();
    }
  });

  return (
    <div className="landing">
      <div className="landing__header">
        <button
          type="button"
          onClick={() => window.parent.postMessage('CLOSE_IFRAME_APP', '*')}
          className="landing__header-home"
        >
          <img className="landing__header-home-icon" src={HomeImage} alt="Skiinfo" />
          <p className="landing__header-home-content">{t('landing.touchButton')}</p>
        </button>
        <div className="landing__header-logo">
          {!loaded && <Placeholder />}
          <img
            className={loaded ? 'landing__header-logo-image' : 'landing__header-logo-image--hide'}
            src={LogoTSS}
            alt="Logo"
            ref={refImage}
            onLoad={onLoad}
          />
        </div>
        <div className="landing__header-hide-elem" />
      </div>
      {loadingStationsList && (
        <div className="loading-error">
          <LoaderDots />
        </div>
      )}
      {errorStationsList && (
        <div className="loading-error">
          <h2>{t('error')}</h2>
        </div>
      )}
      <div className="landing__card">
        {stationsList?.map((station) => (
          <StationCard station={station} key={station.id} />
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
