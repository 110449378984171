import { useTranslation } from 'react-i18next';
import { ReactComponent as Slope } from '../../assets/icon/slope.svg';
import { ReactComponent as Length } from '../../assets/info/length.svg';
import { ReactComponent as Gastronomy } from '../../assets/info/gastronomy.svg';
import { ReactComponent as Traffic } from '../../assets/info/traffic.svg';
import { ReactComponent as Light } from '../../assets/info/artifficial_light.svg';

import './slopeCard.scss';

type SlopeProps = {
  slope: {
    name: string;
    state: string;
    skiRunTraffic: string;
    category: string;
    length: number;
    gastronomicInfrastructure: boolean;
    artificialLight: boolean;
  };
};

const SlopeCard = ({ slope }: SlopeProps) => {
  const { t } = useTranslation();

  return (
    <div className="slope-card">
      <div className="slope-card__lift">
        <div className="slope-card__lift-title">
          {slope.category === 'BLUE' && <Slope className="slope-card__lift-title-icon" />}
          {slope.category === 'RED' && <Slope className="slope-card__lift-title-icon--red" />}
          {slope.category === 'BLACK' && <Slope className="slope-card__lift-title-icon--black" />}
          <div>
            <p>{slope.name}</p>
            <p className="slope-card__lift-title--small">
              {slope.category === 'BLACK' && t('slopes.category.black')}
              {slope.category === 'BLUE' && t('slopes.category.blue')}
              {slope.category === 'RED' && t('slopes.category.red')}
            </p>
          </div>
        </div>
        <div className="slope-card__lift-time">
          <div
            className={
              `${slope.state}` === 'OPEN'
                ? 'slope-card__lift-time-dot'
                : 'slope-card__lift-time-dot--red'
            }
          />
          {slope.state === 'OPEN' ? t('landing.statusOpen') : t('landing.statusClose')}
        </div>
      </div>
      <ul className="slope-card__list">
        <li className="slope-card__list-item">
          <Length className="slope-card__list-item-icon" />
          <p className="slope-card__list-item-text">
            {t('slopes.length')}{' '}
            <span className="slope-card__list-item-text--bold">
              {slope.length !== null ? `${slope.length}m` : '-'}
            </span>
          </p>
        </li>
        <li className="slope-card__list-item">
          <Gastronomy className="slope-card__list-item-icon--orange" />
          <p className="slope-card__list-item-text">
            {t('slopes.gastronomy')}{' '}
            <span className="slope-card__list-item-text--bold">
              {slope.gastronomicInfrastructure ? t('slopes.yes') : t('slopes.no')}
            </span>
          </p>
        </li>
      </ul>
      <ul className="slope-card__list">
        <li className="slope-card__list-item">
          <Traffic className="slope-card__list-item-icon" />
          <p className="slope-card__list-item-text">
            {t('slopes.traffic')}{' '}
            <span className="lift-card__list-item-text--bold">
              {slope.skiRunTraffic === null && '-'}
              {slope.skiRunTraffic === 'NONE' && t('slopes.trafficStatus.none')}
              {slope.skiRunTraffic === 'SCARCE' && t('slopes.trafficStatus.scarce')}
              {slope.skiRunTraffic === 'LOW' && t('slopes.trafficStatus.low')}
              {slope.skiRunTraffic === 'MODERATE' && t('slopes.trafficStatus.moderate')}
              {slope.skiRunTraffic === 'HIGH' && t('slopes.trafficStatus.high')}
              {slope.skiRunTraffic === 'OVERLOADED' && t('slopes.trafficStatus.overloaded')}
            </span>
          </p>
        </li>
        <li className="slope-card__list-item">
          <Light className="slope-card__list-item-icon--blue" />
          <p className="slope-card__list-item-text">
            {t('slopes.light')}{' '}
            <span className="slope-card__list-item-text--bold">
              {slope.artificialLight ? t('slopes.yes') : t('slopes.no')}
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default SlopeCard;
