/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_URL, STATIONS } from '../../api/config';
import LoaderDots from '../../helpers/loader';

import WeatherBox from '../../components/weather/weatherBox';
import WeatherChart from '../../components/weather/weatherChart';

import './weatherPage.scss';

type StationIdProps = {
  id: string | undefined;
};

const WeatherPage = ({ id }: StationIdProps) => {
  const { t } = useTranslation();

  const [weatherData, setWeatherData] = useState<any>([]);
  const [weatherForecast, setWeatherForecast] = useState<any>([]);
  const [loadingStationWeather, setLoadingStationWeather] = useState(false);
  const [errorStationWeather, setErrorStationWeather] = useState(false);
  useEffect(() => {
    let unmounted = false;
    setLoadingStationWeather(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/weather`,
    })
      .then((response) => {
        if (!unmounted) {
          setWeatherData(response.data);
          setWeatherForecast(response.data.futureWeather.slice(0, 9));
          setErrorStationWeather(false);
          setLoadingStationWeather(false);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
          setErrorStationWeather(true);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      {loadingStationWeather && (
        <div className="loading-error">
          <LoaderDots />
        </div>
      )}
      {errorStationWeather && (
        <div className="loading-error">
          <h2>{t('error')}</h2>
        </div>
      )}
      <div className="container">
        <WeatherBox weatherData={weatherData} />
        <WeatherChart weatherData={weatherData} weatherForecast={weatherForecast} />
      </div>
    </>
  );
};

export default WeatherPage;
