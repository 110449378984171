import { useState } from 'react';
import './message.scss';

type IAlert = {
  alert: any[];
};

const Message = ({ alert }: IAlert) => {
  const [messageNumber, setMessageNumber] = useState(0);

  const toggleClick = (index: number) => {
    setMessageNumber(index);
  };

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      if (messageNumber < alert.length - 1) {
        setMessageNumber(messageNumber + 1);
      }
      if (messageNumber === alert.length - 1) {
        setMessageNumber(0);
      }
    }
    if (touchStart - touchEnd < -50) {
      if (messageNumber > 0) {
        setMessageNumber(messageNumber - 1);
      }
      if (messageNumber === 0) {
        setMessageNumber(alert.length - 1);
      }
    }
  };

  return (
    <div
      className={alert.length > 0 ? 'message' : 'message--hide'}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="message__box">
        <img
          className="message__box-icon"
          src={
            alert[messageNumber]?.type === 'WARNING'
              ? `${process.env.PUBLIC_URL}/assets/img/danger.svg`
              : `${process.env.PUBLIC_URL}/assets/img/info.svg`
          }
          alt="Icon"
        />
        <div>
          <h2 className="message__box-text--bold">{alert[messageNumber]?.title}</h2>
          <p className="message__box-text">{alert[messageNumber]?.text}</p>
        </div>
      </div>
      <div className="message__button">
        {alert.length > 0 &&
          alert.map((el, index) => (
            <button
              className="message__button-btn"
              type="button"
              onClick={() => toggleClick(index)}
              key={el.title}
            >
              <div
                className={
                  messageNumber === index
                    ? 'message__button-btn-dot--active'
                    : 'message__button-btn-dot'
                }
              />
            </button>
          ))}
      </div>
    </div>
  );
};

export default Message;
