import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Placeholder from '../../helpers/placeholder';
import LogoPlaceholder from '../../assets/Logo_placeholder.svg';

import { ReactComponent as LiftsIcon } from '../../assets/icon/lifts.svg';
import { ReactComponent as SlopesIcon } from '../../assets/icon/slopes.svg';
// import NavIcon from '../../assets/icon/navigation.svg';
// import WeatherIcon from '../../assets/weather.svg';

import './stationCard.scss';

type StationProps = {
  station: {
    id: string;
    name: string;
    open: boolean;
    openLifts: number;
    totalLifts: number;
    openRuns: number;
    totalRuns: number;
  };
};

const StationCard = ({ station }: StationProps) => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const refImage = useRef<HTMLImageElement>(null);
  const onLoad = () => {
    setLoaded(true);
  };
  useEffect(() => {
    if (refImage.current && refImage.current.complete) {
      onLoad();
    }
  });

  return (
    <Link to={`/${station.id}/lifts`} className="station-card">
      <div className="station-card__logo">
        {!loaded && <Placeholder />}
        <img
          className={loaded ? 'station-card__logo-img' : 'station-card__logo-img--hide'}
          src={LogoPlaceholder}
          alt="Logo"
          ref={refImage}
          onLoad={onLoad}
        />
      </div>
      <div className="station-card__description">
        <h2 className="station-card__description-title">{station.name}</h2>
        <div className="station-card__description-box">
          <div className="station-card__description-box-element">
            <div
              className={
                station.open
                  ? 'station-card__description-box-element-dot'
                  : 'station-card__description-box-element-dot--red'
              }
            />
            <p className="station-card__description-box-element-paragraph">
              {station.open ? t('landing.statusOpen') : t('landing.statusClose')}
            </p>
          </div>
          {/* <div className="station-card__description-box-element">
            <img
              className="station-card__description-box-element-icon"
              src={NavIcon}
              alt="Navigation icon"
            />
            <p className="station-card__description-box-element-paragraph">{station.track}</p>
          </div> */}
          <div className="station-card__description-box-element">
            <LiftsIcon className="station-card__description-box-element-icon" />
            <p className="station-card__description-box-element-paragraph">
              {station.openLifts}/{station.totalLifts}
            </p>
          </div>
          <div className="station-card__description-box-element">
            <SlopesIcon className="station-card__description-box-element-icon" />
            <p className="station-card__description-box-element-paragraph">
              {station.openRuns}/{station.totalRuns}
            </p>
          </div>
          {/* <div className="station-card__description-box-element">
            <img
              className="station-card__description-box-element-icon"
              src={WeatherIcon}
              alt="Weather icon"
            />
            <p className="station-card__description-box-element-paragraph">
              {station.weather}&deg;C
            </p>
          </div> */}
        </div>
      </div>
    </Link>
  );
};

export default StationCard;
