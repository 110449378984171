/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_URL, STATIONS } from '../../api/config';
import LoaderDots from '../../helpers/loader';
import Message from '../../components/message/message';

import LiftCard from '../../components/liftCard/liftCard';
import './liftsPage.scss';

type StationIdProps = {
  id: string | undefined;
};

type LiftProps = {
  name: string;
  status: string;
  length: number;
  rideTime: number;
  lastRunUp: string;
  lastRunDown: string;
  traffic: string;
  liftType: string;
  openHours: {
    dayOfWeek: string;
    from: string;
    to: string;
  };
  openHoursWeek: [];
};

const LiftsPage = ({ id }: StationIdProps) => {
  const { t } = useTranslation();

  const [stationLifts, setStationLifts] = useState([]);
  const [loadingStationLift, setLoadingStationLift] = useState(false);
  const [errorStationLift, setErrorStationLift] = useState(false);
  useEffect(() => {
    let unmounted = false;
    setLoadingStationLift(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/lifts`,
    })
      .then((response) => {
        if (!unmounted) {
          setStationLifts(response.data);
          setErrorStationLift(false);
          setLoadingStationLift(false);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
          setErrorStationLift(true);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  const [alert, setAlert] = useState([]);
  useEffect(() => {
    let unmounted = false;
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/announcements`,
    })
      .then((response) => {
        if (!unmounted) {
          setAlert(response.data);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      {loadingStationLift && (
        <div className="loading-error">
          <LoaderDots />
        </div>
      )}
      {errorStationLift && (
        <div className="loading-error">
          <h2>{t('error')}</h2>
        </div>
      )}
      <div className="container">
        <Message alert={alert} />
        {stationLifts.map((lift: LiftProps) => (
          <LiftCard lift={lift} key={lift.name} />
        ))}
      </div>
    </>
  );
};

export default LiftsPage;
