/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_URL, STATIONS } from '../../api/config';
import LoaderDots from '../../helpers/loader';
import Webcam from '../../components/webcam/webcam';

import './webcamsPage.scss';

type StationIdProps = {
  id: string | undefined;
};

type WebcamProps = {
  url: string;
  name: string;
  cover: string;
};

const WebcamsPage = ({ id }: StationIdProps) => {
  const { t } = useTranslation();

  const [stationWebcams, setStationWebcams] = useState([]);
  const [loadingStationWebcams, setLoadingStationWebcams] = useState(false);
  const [errorStationWebcams, setErrorStationWebcams] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoadingStationWebcams(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/webcams`,
    })
      .then((response) => {
        if (!unmounted) {
          setStationWebcams(response.data);
          setErrorStationWebcams(false);
          setLoadingStationWebcams(false);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
          setErrorStationWebcams(true);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      {loadingStationWebcams && (
        <div className="loading-error">
          <LoaderDots />
        </div>
      )}
      {errorStationWebcams && (
        <div className="loading-error">
          <h2>{t('error')}</h2>
        </div>
      )}
      {stationWebcams.length === 0 && (
        <div className="loading-error">
          <h2>{t('emptyCameras')}</h2>
        </div>
      )}
      <div className="container">
        {stationWebcams.map((webcam: WebcamProps) => (
          <Webcam webcam={webcam} key={webcam.name} />
        ))}
      </div>
    </>
  );
};
export default WebcamsPage;
