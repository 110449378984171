import { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Header from '../../components/header/header';
import NewsPage from '../newsPage/newsPage';
import Navigation from '../../components/navigation/navigation';
import LiftsPage from '../liftsPage/liftsPage';
import SlopesPage from '../slopesPage/slopesPage';
import WeatherPage from '../weatherPage/weatherPage';
import WebcamsPage from '../webcamsPage/webcamsPage';
import './station.scss';

type StationProps = {
  station:
    | {
        id: string;
        name: string;
        open: boolean;
        openLifts: number;
        totalLifts: number;
        openRuns: number;
        totalRuns: number;
      }
    | undefined;
};

const Station = ({ station }: StationProps) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage('fadeOut');
  }, [location]);

  return (
    <div>
      <div className="background">
        <img
          className="background__image"
          src={`${process.env.PUBLIC_URL}/assets/img/background.jpg`}
          alt="Background"
        />
      </div>
      <Header station={station} />
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === 'fadeOut') {
            setTransitionStage('fadeIn');
            setDisplayLocation(location);
          }
        }}
      >
        <Switch location={displayLocation}>
          <Route exact path="/:id/news">
            <NewsPage />
          </Route>
          <Route path="/:id/lifts">
            <LiftsPage id={station?.id} />
          </Route>
          <Route path="/:id/slopes">
            <SlopesPage id={station?.id} />
          </Route>
          <Route path="/:id/weather">
            <WeatherPage id={station?.id} />
          </Route>
          <Route path="/:id/webcams">
            <WebcamsPage id={station?.id} />
          </Route>
        </Switch>
      </div>
      <Navigation station={station} />
    </div>
  );
};

export default Station;
