/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_URL, STATIONS } from '../../api/config';
import LoaderDots from '../../helpers/loader';

import SlopeCard from '../../components/slopeCard/slopeCard';
import Message from '../../components/message/message';
import './slopesPage.scss';

type StationIdProps = {
  id: string | undefined;
};

type SlopeProps = {
  name: string;
  state: string;
  skiRunTraffic: string;
  category: string;
  length: number;
  gastronomicInfrastructure: boolean;
  artificialLight: boolean;
};

const SlopesPage = ({ id }: StationIdProps) => {
  const { t } = useTranslation();

  const [stationSlopes, setStationSlopes] = useState([]);
  const [loadingStationSlopes, setLoadingStationSlopes] = useState(false);
  const [errorStationSlopes, setErrorStationSlopes] = useState(false);
  useEffect(() => {
    let unmounted = false;
    setLoadingStationSlopes(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/runs`,
    })
      .then((response) => {
        if (!unmounted) {
          setStationSlopes(response.data);
          setErrorStationSlopes(false);
          setLoadingStationSlopes(false);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
          setErrorStationSlopes(true);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  const [alert, setAlert] = useState([]);
  useEffect(() => {
    let unmounted = false;
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      url: `${BASE_URL}${STATIONS}/${id}/announcements`,
    })
      .then((response) => {
        if (!unmounted) {
          setAlert(response.data);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          console.error(e);
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      {loadingStationSlopes && (
        <div className="loading-error">
          <LoaderDots />
        </div>
      )}
      {errorStationSlopes && (
        <div className="loading-error">
          <h2>{t('error')}</h2>
        </div>
      )}
      <div className="container">
        <Message alert={alert} />
        {stationSlopes.map((slope: SlopeProps) => (
          <SlopeCard slope={slope} key={slope.name} />
        ))}
      </div>
    </>
  );
};

export default SlopesPage;
