import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Placeholder from '../../helpers/placeholder';
import LogoPlaceholder from '../../assets/Logo_placeholder.svg';

import { ReactComponent as ArrowIcon } from '../../assets/arrow_back.svg';
import { ReactComponent as LiftsIcon } from '../../assets/icon/lifts.svg';
import { ReactComponent as SlopesIcon } from '../../assets/icon/slopes.svg';
// import NavIcon from '../../assets/icon/navigation.svg';
// import WeatherIcon from '../../assets/weather.svg';

import './header.scss';

type StationProps = {
  station:
    | {
        id: string;
        name: string;
        open: boolean;
        openLifts: number;
        totalLifts: number;
        openRuns: number;
        totalRuns: number;
      }
    | undefined;
};

const Header = ({ station }: StationProps) => {
  const { t } = useTranslation();

  const [searchBar, setSearchBar] = useState(false);
  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 1) {
        setSearchBar(true);
      } else {
        setSearchBar(false);
      }
    };

    window.addEventListener('scroll', changeBackground);
  }, []);

  const [loaded, setLoaded] = useState(false);
  const refImage = useRef<HTMLImageElement>(null);
  const onLoad = () => {
    setLoaded(true);
  };
  useEffect(() => {
    if (refImage.current && refImage.current.complete) {
      onLoad();
    }
  });

  return (
    <header className={!searchBar ? 'header' : 'header--background'}>
      <Link className="header__button" to="/">
        <ArrowIcon className="header__button-arrow" />
      </Link>
      <div className="header-card">
        <div className="header-card__logo">
          {!loaded && <Placeholder />}
          <img
            className={loaded ? 'header-card__logo-img' : 'header-card__logo-img--hide'}
            src={LogoPlaceholder}
            alt="Logo"
            ref={refImage}
            onLoad={onLoad}
          />
        </div>
        <div className="header-card__description">
          <h2 className="header-card__description-title">{station?.name}</h2>
          <div className="header-card__description-box">
            <div className="header-card__description-box-element">
              <div
                className={
                  station?.open
                    ? 'header-card__description-box-element-dot'
                    : 'header-card__description-box-element-dot--red'
                }
              />
              <p className="header-card__description-box-element-paragraph">
                {station?.open ? t('landing.statusOpen') : t('landing.statusClose')}
              </p>
            </div>
            {/* <div className="header-card__description-box-element">
              <img
                className="header-card__description-box-element-icon"
                src={NavIcon}
                alt="Navigation icon"
              />
              <p className="header-card__description-box-element-paragraph">{station?.track}</p>
            </div> */}
            <div className="header-card__description-box-element">
              <LiftsIcon className="header-card__description-box-element-icon" />
              <p className="header-card__description-box-element-paragraph">
                {station?.openLifts}/{station?.totalLifts}
              </p>
            </div>
            <div className="header-card__description-box-element">
              <SlopesIcon className="header-card__description-box-element-icon" />
              <p className="header-card__description-box-element-paragraph">
                {station?.openRuns}/{station?.totalRuns}
              </p>
            </div>
            {/* <div className="header-card__description-box-element">
              <img
                className="header-card__description-box-element-icon"
                src={WeatherIcon}
                alt="Weather icon"
              />
              <p className="header-card__description-box-element-paragraph">
                {station?.weather}&deg;C
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
